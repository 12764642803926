import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class LessonService {
  private apiPrefix = '/client/lessons';

  constructor(
    private api: ApiService,
  ) {
  }

  getLessons(queryString = '') {
    return this.api.get(`${this.apiPrefix}${queryString}`);
  }

  getLessonTopics(lessonId: number, queryString = '') {
    return this.api.get(`${this.apiPrefix}/${lessonId}/topics${queryString}`);
  }
}
